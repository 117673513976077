import React, {useEffect, useState} from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Swal from 'sweetalert2';
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import QRCode from 'qrcode';



/**
 * Generates a styled PDF invoice.
 * @param {Object} invoiceData - The data for the invoice.
 * @param {string} invoiceData.customerName - Name of the customer.
 * @param {string} invoiceData.customerAddress - Address of the customer.
 * @param {string} invoiceData.invoiceNumber - Invoice number.
 * @param {string} invoiceData.invoiceDate - Invoice date.
 * @param {Array} invoiceData.items - List of items in the invoice.
 * @param {string} invoiceData.footerNote - Footer note for the invoice.
 */

export default function InvoicePDFGenerator() {
    const location = useLocation();
    const navigate = useNavigate();

    const [invoiceData, setInvoiceData] = useState({
                                                                "id": 1,
                                                                "invoiceType": "A",
                                                                "number": "9988777",
                                                                "pointOfSale": 2,
                                                                "invoiceStatus": "PENDING",
                                                                "totalAmount": 12221,
                                                                "issueDate": "22-12-2024",
                                                                "authorizationDate": "22-12-2024",
                                                                "customerShippingDate": "22-12-2024",
                                                                "expirationDate": "22-12-2024",
                                                                "caeDate": "26-12-2024",
                                                                "numberCae": "34243234243",
                                                                "invoiceCode": "80"
                                                                });
    const [shopData, setShopData] = useState({
                                                             "id": 1,
                                                            "name": "Tienda Karen",
                                                            "address": "Calle Falsa 123, Humboldt - Santa Fe",
                                                            "phone": null,
                                                            "state": null,
                                                            "location": null,
                                                            "businessName": "Tienda SA Karen",
                                                            "pathLogo": null,
                                                            "pathQr": null,
                                                            "principalColorShop": null,
                                                            "image": null,
                                                            "imageFormat": null,
                                                            "modules": null,
                                                            "shopModuleActiveDTO": null,
                                                            "configModuleOrderWhatsappDTO": null,
                                                            "latitude": null,
                                                            "longitude": null,
                                                            "taxCode": null,
                                                            "categoryIva": "MONOTRIBUTISTA",
                                                            "uuid": null
                                                    });

    const [qrImage, setQrImage] = useState(null);

    useEffect(() => {
        if (location.state?.invoiceData) {
            setInvoiceData(location.state.invoiceData);
            setShopData(location.state.invoiceData.shop);

            const afipQRUrl = generateAfipQR(location.state.invoiceData, location.state.invoiceData.shop);
            
            QRCode.toDataURL(afipQRUrl, { 
                errorCorrectionLevel: 'L',
                margin: 2,
                width: 200,
                color: {
                    dark: '#000000',
                    light: '#ffffff'
                }
            })
            .then(url => {
                console.log('QR generado correctamente');
                setQrImage(url);
            })
            .catch(err => console.error("Error generating QR:", err));
        }
    }, [location]);

    // Función para generar el PDF
    const generatePDF = () => {
        if (!invoiceData || !qrImage) {
            Swal.fire("Error", "No se encontraron datos de la factura o el QR no se generó.", "error");
            navigate('/orders');
            return;
        }

        const doc = new jsPDF();
        const copies = ['ORIGINAL', 'DUPLICADO', 'TRIPLICADO'];
        
        // Definir las variables que faltan
        const recuadroFinalHeight = 60; // Altura del recuadro de totales
        const qrMarginBottom = 10; // Margen inferior para el QR
        const qrX = 10; // Posición X del QR (alineado con el margen izquierdo)
        
        copies.forEach((copyType, index) => {
            if (index > 0) {
                doc.addPage();
            }

            // Dimensiones y márgenes
            const pageWidth = doc.internal.pageSize.getWidth();
            const margin = 10;

            // Establecer el grosor de las líneas
            doc.setLineWidth(0.35);

            // Marco principal
            doc.rect(margin, margin, pageWidth - 2 * margin, 280);

            // Recuadro superior con el tipo de copia
            const headerHeight = 8;
            const originalY = margin + 2;
            const originalBoxWidth = pageWidth - 2 * margin - 4;
            const originalBoxHeight = 10;
            const originalBoxX = margin + 2;
            const originalBoxY = originalY + 2;

            // Establecer grosor de línea para el recuadro del tipo de copia
            doc.setLineWidth(0.5);
            doc.setDrawColor(0, 0, 0);
            doc.rect(originalBoxX, originalBoxY, originalBoxWidth, originalBoxHeight);

            // Texto del tipo de copia en negrita y tamaño grande
            doc.setFontSize(14);
            doc.setFont("Times", "bold");
            doc.text(copyType, pageWidth / 2, originalBoxY + originalBoxHeight / 2 + 3, { align: 'center' });

            // Recuadro "Cód" encima del encabezado, superponiéndose
            const cabeceraY = originalY + headerHeight + 5; // Posición ajustada para que quede encima del encabezado
            const smallBoxWidth = 11;
            const smallBoxHeight = 10;
            const smallBoxX = pageWidth / 2 - smallBoxWidth / 2;
            const smallBoxY = cabeceraY * 1.7 - smallBoxHeight * 1.7; // Se superpone sobre el encabezado

            // Ajustar fuente para Tipo factura (más grande)
            doc.setFontSize(14);
            doc.text(invoiceData.invoiceType, smallBoxX + 3, smallBoxY + 5);

            // Establecer el color de relleno como blanco
            doc.setFillColor(255, 255, 255); // Fondo blanco
            doc.setDrawColor(0, 0, 0); // Borde negro

            // Dibujar el rectángulo con relleno blanco
            doc.rect(smallBoxX, smallBoxY, smallBoxWidth, smallBoxHeight, 'FD'); // F: relleno, D: dibujo del borde

            // Ajustar texto dentro del recuadro
            doc.setFont("Times", "bold");
            doc.setFontSize(14);
            doc.text(invoiceData.invoiceType, smallBoxX + 3, smallBoxY + 5);

            doc.setFontSize(8); // Tamaño más pequeño para "Cód. 8"
            doc.text('Cód. ' + invoiceData.invoiceCode, smallBoxX + 1, smallBoxY + 8); // Ajusta las posiciones para centrado

            // Recuadro del encabezado ajustado con grosor 0.5
            doc.setLineWidth(0.5); // Grosor de línea 0.5
            const cabeceraHeight = 50;
            doc.rect(margin + 2, cabeceraY, pageWidth - 2 * margin - 4, cabeceraHeight); // Marco cabecera

            // Ajustar la línea divisoria
            const startY = cabeceraY + 11; // Ajusta este valor para que empiece más abajo
            const endY = cabeceraY + cabeceraHeight ; // Ajusta este valor para que termine antes

            doc.line(pageWidth / 2, startY, pageWidth / 2, endY); // Línea divisoria ajustada

            // Nombre de la empresa (en negrita y grande)
            doc.setFontSize(14);
            doc.setFont("Times", "bold");
            doc.text(shopData.businessName || "-", margin + 6, cabeceraY + 10);

            // Datos del emisor (títulos en negrita, valores en normal, más pequeños)
            doc.setFontSize(10);
            doc.setFont("Times", "bold");
            doc.text("Razón Social:", margin + 6, cabeceraY + 20);
            doc.setFont("Times", "normal");
            doc.text(shopData.businessName || "-", margin + 28, cabeceraY + 20);

            doc.setFont("Times", "bold");
            doc.text("Dirección:", margin + 6, cabeceraY + 25);
            doc.setFont("Times", "normal");
            doc.text(shopData.address || "-", margin + 23, cabeceraY + 25);

            doc.setFont("Times", "bold");
            doc.text("Condición IVA:", margin + 6, cabeceraY + 30);
            doc.setFont("Times", "normal");
            doc.text(shopData.categoryIva || "-", margin + 32, cabeceraY + 30);

            // Información del tipo de factura
            const facturaX = pageWidth / 2 + 6;
            doc.setFontSize(14); // Tamaño más grande para "Factura A"
            doc.setFont("Times", "bold"); // Negrita
            doc.text("FACTURA "+invoiceData.invoiceType, facturaX, cabeceraY + 10);

            doc.setFontSize(10); // Vuelve al tamaño normal para los demás datos
            doc.setFont("Times", "bold");
            doc.text("Punto de Venta:", facturaX, cabeceraY + 20);
            doc.setFont("Times", "normal");
            doc.text("000" + invoiceData.pointOfSale, facturaX + 25, cabeceraY + 20);

            doc.setFont("Times", "bold");
            doc.text("Nº Comprobante:", facturaX, cabeceraY + 25);
            doc.setFont("Times", "normal");
            doc.text(invoiceData.number, facturaX + 28, cabeceraY + 25);

            doc.setFont("Times", "bold");
            doc.text("Fecha de Emisión:", facturaX, cabeceraY + 30);
            doc.setFont("Times", "normal");
            doc.text(invoiceData.issueDate, facturaX + 28, cabeceraY + 30);

            const taxCode = shopData.taxCode ? String(shopData.taxCode) : "N/A";
            doc.setFont("Times", "bold");
            doc.text("CUIT:", facturaX, cabeceraY + 35);
            doc.setFont("Times", "normal");
            doc.text(taxCode, facturaX + 11, cabeceraY + 35);

            doc.setFont("Times", "bold");
            doc.text("Ingresos Brutos:", facturaX, cabeceraY + 40);
            doc.setFont("Times", "normal");
            doc.text(shopData.ingresosBrutos, facturaX + 26, cabeceraY + 40);

            doc.setFont("Times", "bold");
            doc.text("Fecha Inicio Actividad:", facturaX, cabeceraY + 45);
            doc.setFont("Times", "normal");
            doc.text(shopData.activityStartDate, facturaX + 36, cabeceraY + 45);

            // Información del receptor
            const receptorY = cabeceraY + cabeceraHeight + 4;
            const receptorHeight = 30;
            doc.setLineWidth(0.35);
            doc.rect(margin + 2, receptorY, pageWidth - 2 * margin - 4, receptorHeight); // No tiene grosor de línea aquí
            doc.setFont("Times", "bold");
            doc.text("Apellido y Nombre / Razón Social:", margin + 6, receptorY + 10);
            doc.setFont("Times", "normal");
            doc.text(invoiceData.client.fullName, margin + 60, receptorY + 10);

            doc.setFont("Times", "bold");
            doc.text("CUIT:", margin + 6, receptorY + 15);
            doc.setFont("Times", "normal");
            doc.text(invoiceData.client.documentNumber, margin + 17, receptorY + 15);

            doc.setFont("Times", "bold");
            doc.text("Domicilio:", margin + 6, receptorY + 20);
            doc.setFont("Times", "normal");
            doc.text(invoiceData.client.address, margin + 23, receptorY + 20);

            doc.setFont("Times", "bold");
            doc.text("Condición IVA:", margin + 6, receptorY + 25);
            doc.setFont("Times", "normal");
            doc.text(invoiceData.client.categoryIva, margin + 31, receptorY + 25);

            // products
            const productsY = receptorY + receptorHeight + 10;

            // Configurar la tipografía para todo el documento
            doc.setFont("Times", "normal");

        // Tabla de products
            doc.autoTable({
                startY: productsY,
                head: [['Producto/servicio', 'Cant', 'Precio Unit.', 'SubTotal', 'Alicuota IVA', 'Subtotal c/IVA']],
                body: invoiceData.details?.map(item => [
                    item.description, 
                    item.quantity, 
                    formatCurrency(item.unitPrice), 
                    formatCurrency(item.subTotalPriceSinIVA), 
                    `${item.alicuotaIVA}`,
                    formatCurrency(item.subTotalPriceConIVA)
                ]) || [],
                headStyles: {
                    font: "Times",
                    fontStyle: "bold",
                    fontSize: 8,
                    textColor: [0, 0, 0],
                    fillColor: [255, 255, 255],
                    lineWidth: 0.1,
                    lineColor: [0, 0, 0],
                    halign: 'center',
                    valign: 'middle'
                },
                bodyStyles: {
                    font: "Times",
                    fontSize: 8,
                    textColor: [0, 0, 0],
                    fillColor: [255, 255, 255],
                    lineWidth: 0.1,
                    lineColor: [0, 0, 0],
                    halign: 'center',
                    valign: 'middle'
                },
                columnStyles: {
                    0: { // Producto/servicio
                        halign: 'left',
                        cellWidth: 'auto',
                        minCellWidth: 70
                    },
                    1: { // Cantidad
                        cellWidth: 'auto',
                        minCellWidth: 15
                    },
                    2: { // Precio Unit
                        cellWidth: 'auto',
                        minCellWidth: 25,
                        halign: 'right'
                    },
                    3: { // SubTotal
                        cellWidth: 'auto',
                        minCellWidth: 25,
                        halign: 'right'
                    },
                    4: { // Alicuota IVA
                        cellWidth: 'auto',
                        minCellWidth: 25
                    },
                    5: { // Subtotal c/IVA
                        cellWidth: 'auto',
                        minCellWidth: 25,
                        halign: 'right'
                    }
                },
                margin: { left: margin + 2, right: margin + 2 },
                tableWidth: pageWidth - (margin * 2) - 4,
                styles: {
                    cellPadding: 2,
                    fontSize: 8,
                    overflow: 'linebreak'
                },
                didDrawCell: function(data) {
                    // Dibujar todas las líneas de la grilla
                    var doc = data.doc;
                    var cell = data.cell;
                    
                    doc.setDrawColor(0);
                    doc.setLineWidth(0.1);
                    
                    // Dibujar líneas horizontales
                    doc.line(cell.x, cell.y, cell.x + cell.width, cell.y); // Superior
                    doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height); // Inferior
                    
                    // Dibujar líneas verticales
                    doc.line(cell.x, cell.y, cell.x, cell.y + cell.height); // Izquierda
                    doc.line(cell.x + cell.width, cell.y, cell.x + cell.width, cell.y + cell.height); // Derecha
                }
            });

            // Ajustar el posicionamiento de los elementos posteriores
            const finalY = doc.lastAutoTable.finalY + 10;

            // Verificar si hay suficiente espacio en la página actual
            const spaceNeeded = recuadroFinalHeight + 40; // Altura necesaria para el recuadro final + CAE
            const spaceAvailable = doc.internal.pageSize.getHeight() - finalY - margin;

            // Extraer valores
            const numericSubTotal = parseFloat(invoiceData.netoGravado) || 0;
            const numericNoGravado = parseFloat(invoiceData.netoNoGravado) || 0;
            const otrosTributos = parseFloat(invoiceData.otrosTributos) || 0;
            const numericTotal = parseFloat(invoiceData.totalAmount) || 0;

            const Iva = {
                NO_GRAVADO: { id: 1, rate: "0.0" },
                EXENTO: { id: 2, rate: "0.0" },
                GRAVADO_TRES: { id: 3, rate: "0.0" },
                GRAVADO_CUATRO: { id: 4, rate: "0.105" },
                GRAVADO_CINCO: { id: 5, rate: "0.21" },
                GRAVADO_SEIS: { id: 6, rate: "0.27" },
                GRAVADO_OCHO: { id: 8, rate: "0.05" },
                GRAVADO_NUEVE: { id: 9, rate: "0.025" },
                GRAVADO_DIEZ: { id: 10, rate: "0.19" },
                GRAVADO_ONCE: { id: 11, rate: "0.22" },
                GRAVADO_DOCE: { id: 12, rate: "0.18" },
                GRAVADO_DIECISEIS: { id: 16, rate: "0.16" }
            };

            // IVA detallado usando las claves del enum
            const ivaDetails = invoiceData.ivaDetails || {};
            const iva27 = ivaDetails[Iva.GRAVADO_SEIS.id] || 0;    // IVA 27% (Iva.GRAVADO_SEIS => 6)
            const iva21 = ivaDetails[Iva.GRAVADO_CINCO.id] || 0;   // IVA 21% (Iva.GRAVADO_CINCO => 5)
            const iva105 = ivaDetails[Iva.GRAVADO_DIEZ.id] || 0;   // IVA 10.5% (Iva.GRAVADO_DIEZ => 10)
            const iva5 = ivaDetails[Iva.GRAVADO_OCHO.id] || 0;     // IVA 5% (Iva.GRAVADO_OCHO => 8)
            const iva25 = ivaDetails[Iva.GRAVADO_NUEVE.id] || 0;   // IVA 2.5% (Iva.GRAVADO_NUEVE => 9)
            const iva0 = ivaDetails[Iva.NO_GRAVADO.id] || 0;       // IVA 0% (Iva.NO_GRAVADO => 1)

            // Generación de texto en el PDF
            if (spaceAvailable < spaceNeeded) {
                doc.addPage();
                const newFinalY = margin + 10;
                
                // Dibujar recuadro de totales en la nueva página
                doc.setLineWidth(0.50);
                doc.rect(margin + 2, newFinalY, pageWidth - 2 * margin - 4, recuadroFinalHeight);
                
                // Ajustar posición de los textos de totales
                doc.setFontSize(12);
                doc.text(`Importe Neto Gravado: $${numericSubTotal.toFixed(2)}`, pageWidth - margin - 70, newFinalY + 10);
                doc.text(`Importe Neto No Gravado: $${numericNoGravado.toFixed(2)}`, pageWidth - margin - 70, newFinalY + 15);
                doc.text(`IVA 27%: $${iva27.toFixed(2)}`, pageWidth - margin - 70, newFinalY + 20);
                doc.text(`IVA 21%: $${iva21.toFixed(2)}`, pageWidth - margin - 70, newFinalY + 25);
                doc.text(`IVA 10,5%: $${iva105.toFixed(2)}`, pageWidth - margin - 70, newFinalY + 30);
                doc.text(`IVA 5%: $${iva5.toFixed(2)}`, pageWidth - margin - 70, newFinalY + 35);
                doc.text(`IVA 2,5%: $${iva25.toFixed(2)}`, pageWidth - margin - 70, newFinalY + 40);
                doc.text(`IVA 0%: $${iva0.toFixed(2)}`, pageWidth - margin - 70, newFinalY + 45);
                doc.text(`Importe Otros Tributos: $${otrosTributos.toFixed(2)}`, pageWidth - margin - 70, newFinalY + 50);
                doc.text(`Importe Total: $${numericTotal.toFixed(2)}`, pageWidth - margin - 70, newFinalY + 55);
                
                // Ajustar posición del CAE y QR
                doc.text(`CAE Nº: ${invoiceData.numberCae}`, margin + 125, newFinalY + recuadroFinalHeight + 10);
                doc.text(`Fecha de Vto. de CAE: ${invoiceData.caeDate}`, margin + 125, newFinalY + recuadroFinalHeight + 15);
                
                // Ajustar posición del QR en la nueva página
                if (qrImage) {
                    const qrSize = 30;
                    const qrY = doc.internal.pageSize.getHeight() - margin - qrSize - 15;
                    doc.addImage(qrImage, 'PNG', margin + 15, qrY, qrSize, qrSize);
                    
                    // Ajustar el texto debajo del QR
                    doc.setFontSize(7);
                    doc.setFont("Times", "normal");
                    doc.text("Comprobante Autorizado", margin + 15, qrY + qrSize + 4);
                    doc.text("Esta administración federal no se responsabiliza por los datos ingresados en el detalle de la operación",
                        margin + 15, qrY + qrSize + 8);
                }
            } else {
                // Mantener en la página actual si hay espacio suficiente
                doc.setLineWidth(0.50);
                doc.rect(margin + 2, finalY, pageWidth - 2 * margin - 4, recuadroFinalHeight);
                
                // Textos de totales en posición original
                doc.setFontSize(12);
                doc.text(`Importe Neto Gravado: $${numericSubTotal.toFixed(2)}`, pageWidth - margin - 70, finalY + 10);
                doc.text(`Importe Neto No Gravado: $${numericNoGravado.toFixed(2)}`, pageWidth - margin - 70, finalY + 15);
                doc.text(`IVA 27%: $${iva27.toFixed(2)}`, pageWidth - margin - 70, finalY + 20);
                doc.text(`IVA 21%: $${iva21.toFixed(2)}`, pageWidth - margin - 70, finalY + 25);
                doc.text(`IVA 10,5%: $${iva105.toFixed(2)}`, pageWidth - margin - 70, finalY + 30);
                doc.text(`IVA 5%: $${iva5.toFixed(2)}`, pageWidth - margin - 70, finalY + 35);
                doc.text(`IVA 2,5%: $${iva25.toFixed(2)}`, pageWidth - margin - 70, finalY + 40);
                doc.text(`IVA 0%: $${iva0.toFixed(2)}`, pageWidth - margin - 70, finalY + 45);
                doc.text(`Importe Otros Tributos: $${otrosTributos.toFixed(2)}`, pageWidth - margin - 70, finalY + 50);
                doc.text(`Importe Total: $${numericTotal.toFixed(2)}`, pageWidth - margin - 70, finalY + 55);
                
                // CAE y QR en posición original
                doc.text(`CAE Nº: ${invoiceData.numberCae}`, margin + 125, finalY + recuadroFinalHeight + 10);
                doc.text(`Fecha de Vto. de CAE: ${invoiceData.caeDate}`, margin + 125, finalY + recuadroFinalHeight + 15);
                
                if (qrImage) {
                    const qrSize = 30;
                    const qrY = doc.internal.pageSize.getHeight() - margin - qrSize - 15;
                    doc.addImage(qrImage, 'PNG', margin + 15, qrY, qrSize, qrSize);
                    
                    // Ajustar el texto debajo del QR
                    doc.setFontSize(7);
                    doc.setFont("Times", "normal");
                    doc.text("Comprobante Autorizado", margin + 15, qrY + qrSize + 4);
                    doc.text("Esta administración federal no se responsabiliza por los datos ingresados en el detalle de la operación",
                        margin + 15, qrY + qrSize + 8);
                }
            }
        });

        // Guardar el PDF
        const formatFileName = () => {
            const tipoComprobante = `FC${invoiceData.invoiceType}`;
            const puntoVenta = String(invoiceData.pointOfSale).padStart(4, '0');
            const numeroFactura = String(invoiceData.number).padStart(8, '0');
            const fecha = invoiceData.issueDate.replace(/[-/]/g, '');
            
            return `${tipoComprobante}-${puntoVenta}-${numeroFactura}-${fecha}.pdf`;
        };

        doc.save(formatFileName());

        // Mostrar mensaje de éxito
        Swal.fire("Éxito", "Factura generada y descargada con éxito.", "success").then(() => {
            navigate('/orders');
        });
    };

    // Generar PDF cuando los datos estén listos
    useEffect(() => {
        if (invoiceData && qrImage) {
            generatePDF();
        }
    }, [qrImage]); // Solo depende del QR, que es lo último que se genera

    // Retornar null o un componente de carga
    return null; // o return <div>Generando factura...</div>;
}

// Función auxiliar para formatear moneda
function formatCurrency(value) {
    return value ? value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "0.00";
}

const generateAfipQR = (invoiceData, shopData) => {
    try {
        // Crear el objeto con los datos requeridos por AFIP
        const qrData = {
            ver: 1,
            fecha: formatAfipDate(invoiceData.issueDate),
            cuit: Number(String(shopData.taxCode || '').replace(/\D/g, '')),
            ptoVta: Number(invoiceData.pointOfSale),
            tipoCmp: getTipoComprobante(invoiceData.invoiceType),
            nroCmp: Number(String(invoiceData.number || '').replace(/\D/g, '')),
            importe: Number(invoiceData.totalAmount.toFixed(2)),
            moneda: "PES",
            ctz: 1,
            tipoDocRec: getTipoDocumento(invoiceData.client?.documentType) || 96,
            nroDocRec: Number(String(invoiceData.client?.documentNumber || '').replace(/\D/g, '')),
            tipoCodAut: "E",
            codAut: Number(String(invoiceData.numberCae || '').replace(/\D/g, ''))
        };

        // Para debug - ver los datos antes de codificar
        //console.log('QR Data antes de codificar:', qrData);

        // Convertir a JSON y codificar en base64
        const jsonQR = JSON.stringify(qrData);
        //console.log('JSON antes de codificar:', jsonQR);
        
        // Usar btoa directamente sin unescape/encodeURIComponent
        const encodedQR = btoa(jsonQR);
        //console.log('Datos codificados:', encodedQR);

        // URL final
        const finalUrl = `https://serviciosweb.afip.gob.ar/genericos/comprobantes/cae.aspx?p=${encodedQR}`;
        //console.log('URL final del QR:', finalUrl);

        return finalUrl;
    } catch (error) {
        console.error('Error generando QR:', error, {
            invoiceData,
            shopData
        });
        return 'https://www.afip.gob.ar'; // URL por defecto en caso de error
    }
};

// Función auxiliar para formatear la fecha al formato requerido por AFIP
const formatAfipDate = (dateString) => {
    // Asumiendo que dateString viene en formato DD-MM-YYYY
    const parts = dateString.split('-');
    if (parts.length === 3) {
        return `${parts[2]}-${parts[1]}-${parts[0]}`; // Convertir a YYYY-MM-DD
    }
    return dateString; // Retornar sin cambios si no está en el formato esperado
};

// Función auxiliar para obtener el tipo de comprobante según AFIP
const getTipoComprobante = (tipo) => {
    const tipos = {
        'A': 1,  // Factura A
        'B': 6,  // Factura B
        'C': 11, // Factura C
        'M': 51, // Factura M
        'E': 19, // Factura E (Exportación)
        // Tipos de comprobante según tabla proporcionada por AFIP
    };
    return tipos[tipo] || 0;
};

// Función auxiliar para obtener el tipo de documento según AFIP
const getTipoDocumento = (tipo) => {
    const tipos = {
        'DNI': 96,
        'CUIT': 80,
        'CUIL': 86,
        'LE': 89,
        'LC': 90,
        'CI_EXTRANJERA': 91,
        'PASAPORTE': 94,
    };
    return tipos[tipo] || 96; // 96 (DNI) por defecto
};
