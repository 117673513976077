import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import ClientModal from "./ClientModal";
import axios from 'axios';
import Swal from 'sweetalert2';

const InvoiceModal = ({ showModal, setShowModal, orderId, orderClientId}) => {
    const [selectedClient, setSelectedClient] = useState(orderClientId || '');
    const [selectedPointOfSale, setSelectedPointOfSale] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [clients, setClients] = useState([]);
    const [pointsOfSale, setPointsOfSale] = useState([]);
    const [showClientModal, setShowClientModal] = useState(false);
    const [isFormValid, setIsFormValid] = useState(true);
    const [invoiceType, setInvoiceType] = useState('');

    const handleClientAdded = (newClient) => {
        const clientId = newClient.value.data;
        const clientLabel = newClient.label;
        setClients((prevClients) => [
            ...prevClients,
            { id: clientId, fullName: clientLabel, companyName: '' }
        ]);

        setSelectedClient(clientId);
    };

    const getConfig = () => {
        return {
            withCredentials: true,
            params: {
                status: true
            }
        }
    }

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_CLIENTS_ROUTE, getConfig())
                setClients(response.data);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        const fetchPointsOfSale = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_POINT_OF_SALE_ROUTE, {withCredentials: true});
                setPointsOfSale(response.data);
            } catch (error) {
                console.error('Error fetching points of sale:', error);
            }
        };

        fetchClients();
        fetchPointsOfSale();
    }, []);


    const handleSubmit = () => {
        const isClientValid = selectedClient !== '';
        const isPointOfSaleValid = selectedPointOfSale !== '';
        const isInvoiceTypeValid = invoiceType !== '';

        setDueDate(new Date().toISOString().split('T')[0]);
        const isDueDateValid = dueDate !== '';

        setIsFormValid(isClientValid && isPointOfSaleValid && isDueDateValid && isInvoiceTypeValid);

        if (!isClientValid || !isPointOfSaleValid || !isDueDateValid || !isInvoiceTypeValid) {
            return;
        }

        const invoiceRequestDTO = {
            dueDate: dueDate,
            clientId: selectedClient,
            pointOfSale: selectedPointOfSale,
            invoiceType: invoiceType,
        };

        axios
            .post(`${process.env.REACT_APP_API_AUTHORIZER_ROUTE}${orderId}`, invoiceRequestDTO, { withCredentials: true })
            .then((response) => {
                if (response.data.errors && Object.keys(response.data.errors).length > 0) {
                    let errorMessage = 'Errores en la generación de la factura:\n';

                    Object.entries(response.data.errors).forEach(([code, message]) => {
                        errorMessage += `Código: ${code}, Mensaje: ${message}\n`;
                    });

                    if (response.data.notes && response.data.notes.length > 0) {
                        errorMessage += '\nNotas:\n';
                        response.data.notes.forEach(note => {
                            errorMessage += `${note}\n`;
                        });
                    }
                    Swal.fire("Error", errorMessage, "error");
                } else {
                    const { invoiceNumber, CAE, dueDate } = response.data;
                    Swal.fire({
                        icon: "success",
                        title: "Factura generada",
                        text: `Número: ${invoiceNumber}, CAE: ${CAE}, Fecha de vencimiento: ${dueDate}`,
                        timer: 1500,
                        showConfirmButton: false,
                    }).then(() => {
                        handleClose();
                        window.location.reload();
                    });
                }
            })
            .catch((error) => {
                Swal.fire("Error", 'Hubo un error al generar la factura. Por favor, inténtalo nuevamente.', "error");
            });
    };

    const handleClose = () => {
        setDueDate('');
        setSelectedPointOfSale('');
        setSelectedClient(orderClientId);
        setInvoiceType('');
        setIsFormValid(true);
        setShowModal(false);
    };

    const handleModalClose = () => {
        setShowClientModal(false);
    };

    return (
        <>
            <Modal
                show={showModal}
                onHide={handleClose}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Pedido a Facturar</Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Row>
                            {/* Punto de Venta */}
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="pointOfSale">
                                    <Form.Label>Punto de Venta:</Form.Label>
                                    <Form.Select
                                        value={selectedPointOfSale}
                                        onChange={(e) => {
                                            setSelectedPointOfSale(e.target.value);
                                            setIsFormValid(e.target.value !== '' && selectedClient !== '' && dueDate !== '');
                                        }}
                                        isInvalid={!isFormValid && !selectedPointOfSale}
                                    >
                                        <option value="">Selecciona un punto de venta</option>
                                        {pointsOfSale.map((point) => (
                                            <option key={point.id} value={point.id}>
                                                {point.pointOfSale}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            {/* Facturas Permitidas (Factura A, B, C) */}
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="invoiceType">
                                    <Form.Label>Tipo de Factura:</Form.Label>
                                    <Form.Select
                                        value={invoiceType}
                                        onChange={(e) => {
                                            setInvoiceType(e.target.value);
                                            setIsFormValid(e.target.value !== '' && selectedPointOfSale !== '' && selectedClient !== '');
                                        }}
                                        isInvalid={!isFormValid && !invoiceType}
                                    >
                                        <option value="">Seleccione tipo de factura</option>
                                        <option value="INVOICE_A">Factura A</option>
                                        <option value="INVOICE_B">Factura B</option>
                                        <option value="INVOICE_C">Factura C</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            {/* Comentado el código que maneja la fecha de vencimiento hasta que se necesite */}
                            {/*
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="dueDate">
                                    <Form.Label>Fecha de Vencimiento:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={dueDate}
                                        onChange={(e) => {
                                            setDueDate(e.target.value);
                                            setIsFormValid(e.target.value !== '' && selectedPointOfSale !== '' && selectedClient !== '');
                                        }}
                                        isInvalid={!isFormValid && !dueDate}
                                    />
                                </Form.Group>
                            </Col>
                            */}
                        </Row>

                        <Form.Group className="mb-3" controlId="client">
                            <Form.Label>Cliente:</Form.Label>
                            <div className="d-flex align-items-center">
                                <Form.Select
                                    value={selectedClient}
                                    onChange={(e) => {
                                        setSelectedClient(e.target.value);
                                        setIsFormValid(e.target.value !== '' && selectedPointOfSale !== '' && dueDate !== '');
                                    }}
                                    className="me-2"
                                    isInvalid={!isFormValid && !selectedClient}
                                >
                                    <option value="">Selecciona un cliente</option>
                                    {clients.map((client) => (
                                        <option key={client.id} value={client.id}>
                                            {client.fullName} {client.companyName ? `(${client.companyName})` : ''}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Button variant="outline-primary" onClick={() => setShowClientModal(true)}>
                                    <FaPlus /> Agregar Cliente
                                </Button>
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Facturar
                    </Button>
                </Modal.Footer>
            </Modal>
           <ClientModal show={showClientModal} setShow={handleModalClose} onClientAdded={handleClientAdded} />
    </>
    );
};

export default InvoiceModal;
