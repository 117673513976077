import {Button, Col, Container, Pagination, Row, Table} from "react-bootstrap";
import {FaArrowLeft, FaExclamationTriangle} from "react-icons/fa";
import {RequestQuote} from "@carbon/icons-react";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import moment from "moment"; // Usar moment para trabajar con fechas

export default function MisProductos() {

    const keyLocalStorage = 'authToken';
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentNumberOfElements, setCurrentNumberOfElements] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const [expirationsProduct, setExpirationsProduct] = useState([]);

    const today = moment().startOf('day');

    useEffect(() => {
        document.title = 'QReate - Vencimientos de productos';
        getPage();
    }, [currentPage, isLoading]);

    const getPage = () => {
        axios.get(process.env.REACT_APP_API_PRODUCTS_EXPIRATIONS_PAGE_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            },
        })
            .then(response => {
                setExpirationsProduct(response.data.content);
                setTotalPages(response.data.totalPages);
                setCurrentPage(response.data.number + 1);
                setCurrentNumberOfElements(response.data.numberOfElements)
                setTotalElements(response.data.totalElements)
            })
            .catch(error => {
                console.warn(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const loadingTable = () => {
        return (
            <>
                <div className="m-3">
                    <Table responsive className="table-striped">
                        <thead className="tableHead">
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>descripcion</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="3" className="backgroundTable text-center">
                                <div className="loader-container-listado">
                                    <div className="loader"></div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const paginationModule = () => {
        return (
            <Container fluid>
                <Row>
                    <Col className="d-flex align-items-center">
                        <Pagination>
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1}/>
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}
                                             disabled={currentPage === 1}/>
                            {currentPage > 2 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                                    {currentPage - 2}
                                </Pagination.Item>
                            )}
                            {currentPage > 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                    {currentPage - 1}
                                </Pagination.Item>
                            )}
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            {currentPage < totalPages && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                    {currentPage + 1}
                                </Pagination.Item>
                            )}
                            {currentPage < totalPages - 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                                    {currentPage + 2}
                                </Pagination.Item>
                            )}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}
                                             disabled={currentPage === totalPages || expirationsProduct.length === 0}/>
                            <Pagination.Last onClick={() => handlePageChange(totalPages)}
                                             disabled={currentPage === totalPages || expirationsProduct.length === 0}/>
                        </Pagination>
                        <div className="ms-2">
                            <p>Mostrando {currentNumberOfElements} de {totalElements} </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }


    return (
        <div className="containersExpirationsProduct">
            <>
                <Container className="mt-3">
                    <Row>
                        <Col>
                            <h1 className="titleExpirationsProduct">Vencimientos de productos</h1>
                        </Col>
                    </Row>
                </Container>
                {/* Botón para volver */}
                <Button
                    style={{ marginLeft: "10%", marginTop: "2%" }}
                    onClick={() => navigate("/misProductos")}
                >
                    <FaArrowLeft style={{ marginRight: "8px" }} /> Volver a productos
                </Button>

                <div className="productsContainer">
                    <>
                        {isLoading ? (
                            loadingTable()
                        ) : (
                            <div className="m-3">
                                <Table responsive className="table-striped">
                                    <thead className="tableHead">
                                    <tr>
                                        <th>ID</th>
                                        <th>Nombre producto</th>
                                        <th>Fecha de vencimiento</th>
                                        <th>Días de alerta antes del vencimiento</th>
                                        <th>Observación</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {expirationsProduct.length > 0 ? (expirationsProduct.map((expirationProduct) => {
                                        const expirationDate = moment(expirationProduct.expirationDate, "DD-MM-YYYY").startOf('day');
                                        const alertDate = moment(expirationProduct.alertDate, "DD-MM-YYYY").startOf('day');
                                        const isToday = expirationDate.isSame(today);
                                        const isInAlertPeriod = alertDate.isBefore(today) && expirationDate.isAfter(today);
                                        return (
                                            <tr
                                                key={expirationProduct.id}
                                                className={isToday ? "table-danger" : isInAlertPeriod ? "table-warning" : ""}
                                            >
                                                <td className="textCenter fw-bold">
                                                    {expirationProduct.id}
                                                </td>
                                                <td className="textCenter">
                                                    {expirationProduct.productDTO.name}
                                                </td>
                                                <td className="textCenter">
                                                    {expirationProduct.expirationDate}
                                                </td>
                                                <td className="textCenter">
                                                    {expirationProduct.daysAlert}
                                                </td>
                                                <td className="textCenter">
                                                    {expirationProduct.observation}{" "}
                                                    {isToday && (
                                                        <FaExclamationTriangle
                                                            style={{ color: "red", marginLeft: "5px" }}
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })) : (
                                        <tr>
                                            <td colSpan="5" className="text-alig-center">Aun no hay vencimientos cargado
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                            </div>
                        )}
                        {paginationModule()}
                    </>
                </div>
            </>
        </div>
    );
}