import Menu from "../components/Menu/Menu";
import React, {useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import {Container, Row, Col, Button, Table, Pagination, Form, Modal} from 'react-bootstrap';
import {CloseLarge, RequestQuote, Search, TrashCan, View, ViewOff} from '@carbon/icons-react';
import { FaArrowLeft } from 'react-icons/fa';
import {PiPlusMinus} from "react-icons/pi"; // Importar ícono de flecha

export default function StockLocations() {
    const keyLocalStorage = 'authToken';
    const navigate = useNavigate(); // Hook de navegación

    const [locations, setLocations] = useState([]);

    const [showModalAddStockLocation, setShowModalAddStockLocation] = useState(false);
    const [showModalEditStockLocation, setShowModalEditStockLocation] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentNumberOfElements, setCurrentNumberOfElements] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    // Estados de Filtros
    const [nameFilter, setNameFilter] = useState("");
    const [descriptionFilter, setDescriptionFilter] = useState("");

    const [isLoading, setIsLoading] = useState(true);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [createStockAutomatically, setCreateStockAutomatically] = useState(false);

    const [locationIdEdit, setLocationIdEdit] = useState('');
    const [locationNameEdit, setLocationNameEdit] = useState('');
    const [locationDescriptionEdit, setLocationDescriptionEdit] = useState('');

    useEffect(() => {
        document.title = 'QReate - Locaciones de stock';
        getPage();
    }, [currentPage, isLoading]);

    // Armado de la página
    const getPage = () => {
        axios.get(process.env.REACT_APP_STOCK_LOCATION_PAGE_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            },
            params: {
                page: currentPage,
                name: nameFilter,
                description: descriptionFilter
            }
        })
            .then(response => {
                setLocations(response.data.content);
                setTotalPages(response.data.totalPages);
                setCurrentPage(response.data.number + 1);
                setCurrentNumberOfElements(response.data.numberOfElements)
                setTotalElements(response.data.totalElements)
            })
            .catch(error => {
                console.warn(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const cleanFilters = () => {
        setNameFilter("");
        setDescriptionFilter("");
        setIsLoading(true);
        getPage();
    }

    const loadingTable = () => {
        return (
            <>
                <div className="m-3">
                    <Table responsive className="table-striped">
                        <thead className="tableHead">
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>descripcion</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="3" className="backgroundTable text-center">
                                <div className="loader-container-listado">
                                    <div className="loader"></div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }

    const paginationModule = () => {
        return (
            <Container fluid>
                <Row>
                    <Col className="d-flex align-items-center">
                        <Pagination>
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1}/>
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}
                                             disabled={currentPage === 1}/>
                            {currentPage > 2 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                                    {currentPage - 2}
                                </Pagination.Item>
                            )}
                            {currentPage > 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                    {currentPage - 1}
                                </Pagination.Item>
                            )}
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            {currentPage < totalPages && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                    {currentPage + 1}
                                </Pagination.Item>
                            )}
                            {currentPage < totalPages - 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                                    {currentPage + 2}
                                </Pagination.Item>
                            )}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}
                                             disabled={currentPage === totalPages || locations.length === 0}/>
                            <Pagination.Last onClick={() => handlePageChange(totalPages)}
                                             disabled={currentPage === totalPages || locations.length === 0}/>
                        </Pagination>
                        <div className="ms-2">
                            <p>Mostrando {currentNumberOfElements} de {totalElements} </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    // Manejo de filtros
    const filtersContainer = () => {
        return (
            <Container>
                <Row className="mt-3 justify-content-center" xs={2} md={3}>
                    {/* Filtro por Nombre: Guarda el nombre del producto */}
                    <Col>
                        <Form.Group controlId="nameFilter">
                            <Form.Control
                                className="filtersStyle"
                                type="text"
                                placeholder="Buscar por nombre"
                                value={nameFilter}
                                onChange={(e) => setNameFilter(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    {/* Filtro por Descripción: Guarda la descripción del producto */}
                    <Col>
                        <Form.Group controlId="descriptionFilter">
                            <Form.Control
                                className="filtersStyle"
                                type="text"
                                placeholder="Buscar por descripción"
                                value={descriptionFilter}
                                onChange={(e) => setDescriptionFilter(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    {/* Botones de Búsqueda y Limpiar */}
                    <Col className="text-center text-md-start mt-3 mt-md-0 mb-sm-3 mb-md-0">
                        <Button id="btnSearchProduct" className="me-1" variant="primary" onClick={() => getPage()}>
                            <Search/>
                        </Button>
                        <Button id="btnCleanSearchProducts" className="ms-1" variant="primary" onClick={() => cleanFilters()}>
                            <CloseLarge/>
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    };

    const modalControlButtons = () => {
        return (
            <Container className="mt-3">
                <Row>
                    <Col className="text-alig-center">
                        <Button variant="primary" className="me-1"
                                onClick={() => setShowModalAddStockLocation(true)}>Agregar</Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    // Estados para el manejo de errores
    const [errors, setErrors] = useState({
        name: false,
        description: false,
    });

    const [errorsEdit, setErrorsEdit] = useState({
        locationNameEdit: false,
        locationDescriptionEdit: false,
    });

    const handleSaveStockLocation = () => {
        if (validateFields()) {
            saveStockLocation(); // Llamar a la función para guardar si no hay errores
        }
    };

    const handleEditStockLocation = () => {
        if (validateFieldsEdit()) {
            editStockLocation(); // Llamar a la función para guardar si no hay errores
        }
    };

    const validateFields = () => {
        const newErrors = {
            name: name === '',
            description: description === '',
        };
        setErrors(newErrors);

        // Retornar true si no hay errores
        return !Object.values(newErrors).includes(true);
    };

    const validateFieldsEdit = () => {
        const newErrorsEdit = {
            locationNameEdit: locationNameEdit === '',
            locationDescriptionEdit: locationDescriptionEdit === '',
        };
        setErrorsEdit(newErrorsEdit);

        // Retornar true si no hay errores
        return !Object.values(newErrorsEdit).includes(true);
    };

    const saveStockLocation = () => {
        const stockLocationDTO = {
            name: name,
            description: description,
            createStockAutomatically: createStockAutomatically
        }
        axios.post(process.env.REACT_APP_STOCK_LOCATION_ROUTE, stockLocationDTO, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Creado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setName('');
                setDescription('');
                setCreateStockAutomatically(false);
                setShowModalAddStockLocation(false);
                getPage();
            })
            .catch(error => {
                const errorMessage = error.response?.data.message || 'Ocurrió un error inesperado';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                    confirmButtonText: 'Cerrar',
                });
            });
    }

    const editStockLocation = () => {
        const stockLocationDTO = {
            id: locationIdEdit,
            name: locationNameEdit,
            description: locationDescriptionEdit
        }
        axios.put(process.env.REACT_APP_STOCK_LOCATION_ROUTE + "/" + locationIdEdit, stockLocationDTO, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Editado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setLocationNameEdit('');
                setLocationDescriptionEdit('');
                setShowModalEditStockLocation(false);
                getPage();
            })
            .catch(error => {
                const errorMessage = error.response?.data.message || 'Ocurrió un error inesperado';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                    confirmButtonText: 'Cerrar',
                });
            });
    }

    const addStockLocationModal = () => {
        return (
            <Modal className="modal fade modal-lg" show={showModalAddStockLocation} onHide={() => setShowModalAddStockLocation(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5 titleModalCreateProduct" id="modaleAddProductLabel">
                        Nueva locacion de stock
                    </h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setErrors({
                                    name: false,
                                    description: false,
                                });
                                setName('');
                                setDescription('');
                                setCreateStockAutomatically(false);
                                setShowModalAddStockLocation(false);
                            }}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {/* Campo de Cantidad de Stock */}
                    <div className="mb-3">
                        <label htmlFor="quantity" className="col-form-label">Nombre:</label>
                        <input type="text" className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                               id="quantity" min="0" value={name}
                               onChange={(e) => setName(e.target.value)}/>
                        {errors.name && (
                            <div className="invalid-feedback">Campo obligatorio</div>
                        )}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="quantity" className="col-form-label">Descripcion:</label>
                        <input type="text" className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                               id="quantity" min="0" value={description}
                               onChange={(e) => setDescription(e.target.value)}/>
                        {errors.description && (
                            <div className="invalid-feedback">Campo obligatorio</div>
                        )}
                    </div>
                    <div className="form-check-inline-createAllStock mb-3">
                        <input
                            className="form-check-input-cancel"
                            type="checkbox"
                            id="createStockAutomatically"
                            checked={createStockAutomatically}
                            onChange={(e) => setCreateStockAutomatically(e.target.checked)}
                        />
                        <label className="form-check-label-createAllStock" htmlFor="createStockAutomatically">
                            Crear automáticamente los stock de todos los productos para esta Locación
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        setErrors({
                            name: false,
                            description: false,
                        });
                        setName('');
                        setDescription('');
                        setCreateStockAutomatically(false);
                        setShowModalAddStockLocation(false);
                    }}>
                        Cerrar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={handleSaveStockLocation}>
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    const editStockLocationModal = () => {
        return (
            <Modal className="modal fade modal-lg" show={showModalEditStockLocation} onHide={() => setShowModalEditStockLocation(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5 titleModalCreateProduct" id="modaleAddProductLabel">
                        Editar locacion de stock ID: {locationIdEdit}
                    </h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setErrorsEdit({
                                    locationNameEdit: false,
                                    locationDescriptionEdit: false,
                                });
                                setLocationNameEdit('');
                                setLocationDescriptionEdit('');
                                setShowModalEditStockLocation(false);
                            }}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {/* Campo de Cantidad de Stock */}
                    <div className="mb-3">
                        <label htmlFor="quantity" className="col-form-label">Nombre:</label>
                        <input type="text" className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                               id="quantity" min="0" value={locationNameEdit}
                               onChange={(e) => setLocationNameEdit(e.target.value)}/>
                        {errors.name && (
                            <div className="invalid-feedback">Campo obligatorio</div>
                        )}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="quantity" className="col-form-label">Descripcion:</label>
                        <input type="text" className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                               id="quantity" min="0" value={locationDescriptionEdit}
                               onChange={(e) => setLocationDescriptionEdit(e.target.value)}/>
                        {errors.description && (
                            <div className="invalid-feedback">Campo obligatorio</div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        setErrorsEdit({
                            locationNameEdit: false,
                            locationDescriptionEdit: false,
                        });
                        setLocationNameEdit('');
                        setLocationDescriptionEdit('');
                        setShowModalEditStockLocation(false);
                    }}>
                        Cerrar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={handleEditStockLocation}>
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    const editLocation = (location) => {
        setLocationIdEdit(location.id);
        setLocationNameEdit(location.name);
        setLocationDescriptionEdit(location.description);
    }

    return (
        <div className="containersStock">

                <>
                    <Container className="mt-3">
                        <Row>
                            <Col>
                                <h1 className="titleStock">Locaciones de stock</h1>
                            </Col>
                        </Row>
                    </Container>
                    {/* Botón para volver */}
                    <Button
                        style={{ marginLeft: '10%', marginTop: '2%' }}
                        onClick={() => navigate('/stock')}
                    >
                        <FaArrowLeft style={{ marginRight: '8px' }} /> Stock
                    </Button>

                    <div className="productsContainer">

                            <>
                                {filtersContainer()}

                                {(isLoading) ? (
                                    loadingTable()
                                ) : (
                                    <div className="m-3">
                                        <Table responsive className="table-striped">
                                            <thead className="tableHead">
                                            <tr>
                                                <th>ID</th>
                                                <th>Nombre</th>
                                                <th>Descripcion</th>
                                                <th style={{width: '5%'}}></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                locations.map(location => (
                                                    <tr key={location.id}>
                                                        <td className="text-alig-center fw-bold">{location.id}</td>
                                                        <td className="text-alig-center">{location.name}</td>
                                                        <td className="text-alig-center">{location.description}</td>
                                                        <td className="text-alig-center">
                                                            <button className="btn btnEdit"><RequestQuote
                                                                className="btnEdit" size="24"
                                                                onClick={() => {
                                                                    editLocation(location);
                                                                    setShowModalEditStockLocation(true);
                                                                }}/></button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </Table>

                                    </div>
                                )}
                                {paginationModule()}
                                {modalControlButtons()}
                                {addStockLocationModal()}
                                {editStockLocationModal()}
                            </>
                    </div>
                </>
        </div>
    );
}
