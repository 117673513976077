import React, { createContext, useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import {
    connectWebSocket,
    sendConfigUpdateDelivery, sendConfigUpdateDeliveryCustom,
    sendConfigUpdateWhatsapp
} from '../config/WebsocketService';

const ConfigContext = createContext();


const ConfigProvider = ({ children }) => {
  const keyLocalStorage = 'authToken';

  const [config, setConfig] = useState({
                                                      "id": 1,
                                                      "orderStartTime": null,
                                                      "orderEndTime": null,
                                                      "deliveryType": "COMMON",
                                                      "idDelivery": 1,
                                                      "shopId": 1,
                                                      "status": false,
                                                      "nameFrontFilter": "isOrderActive",
                                                      "verifyAddress": false,
                                                      "ruralSettings":false,
                                                      "priceRuralBase":"00",
                                                      "deliveryCommon": {
                                                          "id": 0,
                                                          "priceBase": "00",
                                                          "metersBase": 0,
                                                          "priceMetersExcessive": "00",
                                                          "cantMetersExcessive": 0,
                                                          "metersMax": 0,
                                                          "nameFrontFilter": "isDelivery",
                                                          "active": false
                                                      },
                                                      "deliveryCustom":{
                                                            "id": 0,
                                                            "priceBase": "00",
                                                            "rangeMaxMeters": 0,
                                                            "nameFrontFilter": "isDelivery",
                                                            "active": false
                                                      },
                                                      "priceActive": true
  });

  const [features, setFeatures] = useState([
                                                                      {
                                                                          "id": 1,
                                                                          "name": "MODULO BASICO",
                                                                          "status": true,
                                                                          "createdAt": null
                                                                      },
                                                                      {
                                                                          "id": 2,
                                                                          "name": "REPORTES",
                                                                          "status": false,
                                                                          "createdAt": null
                                                                      },
                                                                      {
                                                                          "id": 3,
                                                                          "name": "CONFIGURACION EXTENDIDA",
                                                                          "status": false,
                                                                          "createdAt": null
                                                                      },
                                                                      {
                                                                          "id": 4,
                                                                          "name": "GEOLOCALIZACION",
                                                                          "status": false,
                                                                          "createdAt": null
                                                                      }
                                                                  ]);


    /******************************************************
            METODOS GENERICOS DE CONFIGURACIONES
     ******************************************************/

    // Método para obtener las flag de modulos NEGOCIO (visualiza reportes - stock , etc )
    const fetchFeatures = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_FEATURE_SELECTOR_ROUTE}`, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                }
            });
            setFeatures(response.data);
        } catch (error) {
            console.error('Error al obtener las características', error);
        }
    };

    //Metodo para obtener toda la config de whatsap y delivery
    const fetchConfigs = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_MODULES_CONFIGS_ROUTE, {
        withCredentials: true
      });
      if (response.data) {
           setConfig(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
      connectWebSocket((newConfig) => {
          setConfig(newConfig);
      });

     fetchConfigs();
     fetchFeatures();
  }, []);



    /******************************************************
            METODOS DE CONFIGURACIONES DE WHATSAPP
     ******************************************************/

   // Método que se llama cuando se cambia la configuracion de pedido activo
    const toggleOrders = (isChecked) => {
        const updatedConfig = {
            ...config,
            status: isChecked,
        };

        // Si los pedidos están activos y la visualización de precios no, activa la visualización de precios
        if (isChecked && config.priceActive === false) {
            showErrorMessage('Se tienen que activar la visualizacion de precios.');
        } else {
            // Enviar la actualización al WebSocket
            sendConfigUpdateWhatsapp(updatedConfig);
            setConfig(updatedConfig);
            showSuccessMessage('Estado pedidos actualizado.');
        }
    };

    // Método que se llama cuando se cambia la configuracion de visualizacion de precios
    const toggleActivePrice = (newActivePrice) => {
        const updatedConfig = {
            ...config,
            priceActive: newActivePrice,
        };

        // Si los pedidos están activos y la visualización de precios no, activa la visualización de precios
        if (newActivePrice === false && config.status === true) {
                showErrorMessage('Se tiene que desactivar los pedidos.');
        } else {
            // Enviar la actualización al WebSocket
            sendConfigUpdateWhatsapp(updatedConfig);
            setConfig(updatedConfig);
            showSuccessMessage('Estado visualizacion precios actualizado.');
        }
    };

    // Método que se llama cuando se cambia la configuracion de verificacion de ubicacion
    const toggleVerifyAddress = (isChecked) => {
        const updatedConfig = {
            ...config,
            verifyAddress: isChecked,
        };
        // Enviar la actualización al WebSocket
        sendConfigUpdateWhatsapp(updatedConfig);
        setConfig(updatedConfig);
        showSuccessMessage('Estado verificacion ubicaciones actualizado.');

    };

    // Método que se llama cuando se cambia la configuracion de verificacion zona rural
    const toggleVerifyZonaRural = (isChecked) => {
        const updatedConfig = {
            ...config,
            ruralSettings: isChecked,
        };
        // Enviar la actualización al WebSocket
        sendConfigUpdateWhatsapp(updatedConfig);
        setConfig(updatedConfig);
        showSuccessMessage('Estado zona rural actualizado.');

    };

    // Método que se llama cuando se cambia la configuracion de precio de delivery
    const changeDeliveryPriceRural = (newDeliveryPrice) => {
        const updatedConfig = {
            ...config,
            priceRuralBase: newDeliveryPrice,
        };

        // Enviar la actualización al WebSocket
        sendConfigUpdateWhatsapp(updatedConfig);
        setConfig(updatedConfig);
        showSuccessMessage('Precio delivery rural actualizado.');
    };


    function isDeliveryActive(config) {
        if (config.idDelivery !== null &&  config.deliveryType !== "NO_DELIVERIES") {
            return config.deliveryType === 'COMMON'
                ? config.deliveryCommon.active
                : config.deliveryType === 'CUSTOM'
                    ? config.deliveryCustom.active
                    : false;
        }
        return false;
    }

    /******************************************************
            METODOS DE CONFIGURACIONES DE DELIVERY COMMON
     ******************************************************/

    // Método que se llama cuando se cambia la configuracion de status de delivery
    const toggleDelivery = (isChecked) => {
        const updatedConfig = {
            ...config,
            deliveryCommon: {
                ...config.deliveryCommon,
                active: isChecked,
            },
        };

        // Enviar la actualización al WebSocket
        sendConfigUpdateDelivery(updatedConfig.deliveryCommon);
        setConfig(updatedConfig);
        showSuccessMessage('Estado delivery actualizado.');

    };

    // Método que se llama cuando se cambia la configuracion de precio de delivery
    const changeDeliveryPrice = (newDeliveryPrice) => {
        const updatedConfig = {
            ...config,
            deliveryCommon: {
                ...config.deliveryCommon,
                priceBase: newDeliveryPrice,
            },
        };

        // Enviar la actualización al WebSocket
        sendConfigUpdateDelivery(updatedConfig.deliveryCommon);
        setConfig(updatedConfig);
        showSuccessMessage('Precio delivery actualizado.');
    };

    // Método que se llama cuando se cambia la configuracion de distancia de delivery
    const changeDeliveryRange = (newDeliveryRange) => {
        const updatedConfig = {
            ...config,
            deliveryCommon: {
                ...config.deliveryCommon,
                rangeMaxMeters: newDeliveryRange,
            },
        };

        // Enviar la actualización al WebSocket
        sendConfigUpdateDelivery(updatedConfig.deliveryCommon);
        setConfig(updatedConfig);
        showSuccessMessage('Rango delivery actualizado.');
    };



    /******************************************************
        METODOS DE CONFIGURACIONES DE DELIVERY CUSTOM
     ******************************************************/

        // Método que se llama cuando se cambia la configuracion de status de delivery
    const toggleDeliveryCustom = (isChecked) => {
            const updatedConfig = {
                ...config,
                deliveryCustom: {
                    ...config.deliveryCustom,
                    active: isChecked,
                },
            };

            // Enviar la actualización al WebSocket
            sendConfigUpdateDeliveryCustom(updatedConfig.deliveryCustom);
            setConfig(updatedConfig);
            showSuccessMessage('Estado delivery actualizado.');
        };

    // Método que se llama cuando se cambia la configuracion de precio de delivery
    const changeDeliveryPriceCustom = (newDeliveryPrice) => {
        const updatedConfig = {
            ...config,
            deliveryCustom: {
                ...config.deliveryCustom,
                priceBase: newDeliveryPrice,
            },
        };

        // Enviar la actualización al WebSocket
        sendConfigUpdateDeliveryCustom(updatedConfig.deliveryCustom);
        setConfig(updatedConfig);
        showSuccessMessage('Precio delivery actualizado.');
    };

    // Método que se llama cuando se cambia la configuracion de distancia de delivery
    const changeDeliveryRangeCustom = (newDeliveryRange) => {
        const updatedConfig = {
            ...config,
            deliveryCustom: {
                ...config.deliveryCustom,
                metersBase: newDeliveryRange,
            },
        };

        // Enviar la actualización al WebSocket
        sendConfigUpdateDeliveryCustom(updatedConfig.deliveryCustom);
        setConfig(updatedConfig);
        showSuccessMessage('Rango delivery actualizado.');
    };

    // Método que se llama cuando se cambia la configuracion de precio de rangos excedidos
    const changeDeliveryPriceMetersExcessiveCustom = (newDeliveryPrice) => {
        const updatedConfig = {
            ...config,
            deliveryCustom: {
                ...config.deliveryCustom,
                priceMetersExcessive: newDeliveryPrice,
            },
        };

        // Enviar la actualización al WebSocket
        sendConfigUpdateDeliveryCustom(updatedConfig.deliveryCustom);
        setConfig(updatedConfig);
        showSuccessMessage('Precio delivery actualizado.');
    };

    // Método que se llama cuando se cambia la configuracion de metros de rango de delivery
    const changeDeliveryCantMetersExcessiveCustom = (newDeliveryRange) => {
        const updatedConfig = {
            ...config,
            deliveryCustom: {
                ...config.deliveryCustom,
                cantMetersExcessive: newDeliveryRange,
            },
        };

        // Enviar la actualización al WebSocket
        sendConfigUpdateDeliveryCustom(updatedConfig.deliveryCustom);
        setConfig(updatedConfig);
        showSuccessMessage('Rango delivery actualizado.');
    };

    // Método que se llama cuando se cambia la configuracion de metros de rango de delivery
    const changeDeliveryMetersMaxCustom = (newDeliveryRange) => {
        const updatedConfig = {
            ...config,
            deliveryCustom: {
                ...config.deliveryCustom,
                metersMax: newDeliveryRange,
            },
        };

        // Enviar la actualización al WebSocket
        sendConfigUpdateDeliveryCustom(updatedConfig.deliveryCustom);
        setConfig(updatedConfig);
        showSuccessMessage('Rango delivery actualizado.');
    };


    const showSuccessMessage = (message) => {
        Swal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: message,
            timer: 2000,
            showConfirmButton: false,
        });
    };

    const showErrorMessage = (message) => {
        Swal.fire({
            icon: 'error',
            title: '¡Atención!',
            text: message,
            timer: 2000,
            showConfirmButton: false,
        });
    };


    return (
    <ConfigContext.Provider value={{
        config,
        toggleOrders,
        toggleDelivery,
        toggleVerifyAddress,
        changeDeliveryPrice,
        changeDeliveryRange,
        features,
        toggleActivePrice,
        toggleDeliveryCustom,
        changeDeliveryPriceCustom,
        changeDeliveryRangeCustom,
        changeDeliveryPriceMetersExcessiveCustom,
        changeDeliveryCantMetersExcessiveCustom,
        changeDeliveryMetersMaxCustom,
        toggleVerifyZonaRural,
        changeDeliveryPriceRural
    }}>
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigProvider, ConfigContext };

export function useConfig() {
  return useContext(ConfigContext);
}